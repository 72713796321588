import baseTheme from "gatsby-theme-gallery/src/gatsby-plugin-theme-ui"
import merge from "lodash.merge"

export default merge({}, baseTheme, {
  colors: {
    text: "#222",
    background: "rgb(37, 43, 45)",
    modes: {
      dark: {
        text: "#fff",
        background: "rgb(37, 43, 45)",
        primary: "#A5F",
      },
    },
  },
})
